import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import State from "./components/State"
import StickyNav from "./components/StickyNav"
import { Home, View, Upload, VendorReport, S3DocumentUpload, ViewInvoice } from  "./components"

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('home-services-app'))
root.render(
    <Router>
        <Routes>
            <Route path="/" element={ <Home/> }/>
            <Route path="/photo/view" element={ <View/> }/>
            <Route path="/photo/upload" element={ <Upload/> }/> 
            <Route path="/report/vendor" element={ <VendorReport/>}/>
            <Route path="/document/s3upload" element={ <S3DocumentUpload/> }/>
            <Route path="/invoice/view" element={ <ViewInvoice/> }/>
            <Route path='*' exact={true} element={ <><StickyNav/><State status="error" title="404 Not Found" message="The page doesn't exist"/></> } />
        </Routes>
    </Router>
);
