import React from "react"
import Async from 'react-async'
import State from "./State"
import { Document, Page, pdfjs } from "react-pdf"

import StickyNav from "./StickyNav"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const checkVendorReport = async ( { extid }) => {
    let url = `/api/report/checker/vendor/?extid=${extid}`
    const res = await fetch(url)
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

function VendorReport() {
    const queryParams = new URLSearchParams(window.location.search)
    
    const id = queryParams.get('extid')
    const name = queryParams.get('name')
    document.title = `Service Report (${name})`
    return (
        <>
            <StickyNav/>
            { !id ?
                <State status="error" title="Internal Server Error" message="id is required."/>
                :
                <Async promiseFn={checkVendorReport} extid={id}>
                    <Async.Loading><State status="info" title="Loading ..." message="Fetching data, please wait!"/></Async.Loading>
                    <Async.Rejected>
                        {error => <State status="error" title="Error" message="Something went wrong."/> }
                    </Async.Rejected>
                    <Async.Fulfilled>
                        {data => {
                            if(data.totalSize > 0) {
                                let cvid = data.records[0].ContentVersionDocument.Id
                                let title = data.records[0].ContentVersionDocument.Title
                                return (
                                    <Document
                                        file={`/api/report/vendor/?extid=${cvid}&title=${title}`}
                                        onLoadError={(error) => console.log("Inside Error", error)}>
                                        <Page pageNumber={1} />
                                    </Document>
                                )
                            } else {
                                return (
                                    <State status="error" title="Error" message="No Service Report"/>
                                )
                            }
                        }}
                    </Async.Fulfilled>
                </Async>
            }
        </>
    )
}

export default VendorReport