import React, { useState } from "react"
import { Lazy, Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import StickyNav from "./StickyNav"
import State from "./State"

import { Container, Button, Row, Col, ButtonGroup, Form, Modal } from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.min.css"

import "swiper/css"
import "swiper/css/lazy"
import "swiper/css/pagination"
import "swiper/css/navigation"

function Upload() {
    const queryParams = new URLSearchParams(window.location.search)
    const [photos, setPhotos] = useState([])
    const [description, setDescription] = useState('')
    const [savingMessage, setSavingMessage] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [widget, setWidget] = useState(null)

    const handleDescriptionChange = event => {
        setDescription(event.target.value)
    }

    const addPhoto = (newPhoto) => setPhotos(state => [...state, newPhoto])
    const id = queryParams.get('extid')
    const obj = queryParams.get('object')
    const name = queryParams.get('name')
    
    document.title = `Upload Photos (${name})`

    const onCheckPhotos = () => {
        if (!photos.length > 0) {
            setModalMessage('Please add photos!')
            setDisableSubmit(true)
            setShowModal(true)
        } else {
            setModalMessage(`Sure to save ${photos.length} ${photos.length > 1 ? 'photos' : 'photo'}?`)
            setDisableSubmit(false)
            setShowModal(true)
        }
    }

    const onShowWidget = async () => {
        if (!widget) {
            const response = await fetch('/api/cloudinary-upload-preset');
            const json = await response.json();
            const options = {
                styles:{
                    palette: {
                        window: "#FFF",
                        windowBorder: "#90A0B3",
                        tabIcon: "#FF7300",
                        menuIcons: "#FF7300",
                        textDark: "#000000",
                        textLight: "#FFFFFF",
                        link:  "#FF7300",
                        action:  "#FF620C",
                        inactiveTabIcon: "#FF7300",
                        error: "#F44235",
                        inProgress: "#0078FF",
                        complete: "#20B832",
                        sourceBg: "#E4EBF1"
                    },
                    frame: {
                      background: "#0E2F5B99"
                    }
                },
                cloudName: json.cloudName,
                showPoweredBy: false,
                uploadPreset: json.uploadPreset,
                thumbnail_transformation: { width: 500, height: 500, crop: "fit" }
            }
            let mywidget = window.cloudinary.createUploadWidget( options, processResults)
            setWidget(mywidget)
            mywidget.open()
        } else {
            widget.open()
        }
    }

    const onCancel = () => {
        setModalMessage('')
        setDisableSubmit(false)
        setShowModal(false)
    }

    const onSave = async () => {
        let maintenancePhotos = []
        if (photos) {
            let object
            let url
            if (obj === 'case') {
                object = 'Maintenance_Case__c'
                url = '/api/photos/case/'
            } else if (obj === 'lineitem') {
                object = 'Work_Order_Line_Item__c'
                url = '/api/photos/lineitem/'
            }
            // eslint-disable-next-line array-callback-return
            photos.map(photo => {
                let maintenancePhoto = { Description__c :  description, Image_URL__c : photo.url, [object] : id }
                maintenancePhotos.push(maintenancePhoto)
            })
            if (maintenancePhotos.length > 0) {
                setDisableSubmit(true)
                setSavingMessage('Saving ...')

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(maintenancePhotos)
                }

                fetch(url, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setSavingMessage('Saved ...')
                        setPhotos([])
                        setDescription('')
                        setTimeout(()=> {
                            setDisableSubmit(false)
                            setShowModal(false)
                            setSavingMessage('')
                        }, 2000)
                    })
            }
            
        }
    }

    const processResults = (error, result) => {
        if (!error && result && result.event === "success") {
            addPhoto({ url: result.info.secure_url, thumb_url: result.info.thumbnail_url})
        }
    }

    const removePhoto = (event, url) => {
        event.preventDefault()
        var tempPhotos = [...photos]
        const index = tempPhotos.findIndex(photo => {
            return photo.url === url
        })
        tempPhotos.splice(index, 1)
        setPhotos(tempPhotos)
    }
    
    return (
        <>
            <StickyNav/>
            { !id || !obj ?
                <State status="error" title="Internal Server Error" message="id and object is required."/>
                :
                <>
                    <Container className="content-container">
                        <Row lg={2}>
                            <Col sm={6} className="swiper-border-container">
                                <Swiper lazy={true} pagination={{ type: "fraction" }} navigation={true} modules={[Lazy, Pagination, Navigation]} id="uploadSwiper">
                                    {!photos.length > 0 && photos ?
                                        <SwiperSlide key="no-image">
                                            <State status="info" title="No Photos" message="Please add photos"/>
                                        </SwiperSlide>
                                    : photos.map(photo=> (
                                        <SwiperSlide key={photo.url}>
                                            <div id="remove-button-container" className="float-end">
                                                <button id="remove-button" onClick={(e) => { removePhoto(e, photo.url ) }}></button>
                                            </div>
                                            <img data-src={photo.thumb_url} src={photo.thumb_url} alt="" className="swiper-lazy"/>
                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Col>
                            <Col sm={6} className="action-border-container">
                                <Form id="actions">
                                    <Form.Group className="mb-3">
                                        <Form.Label id="maintenance-label">Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} id="maintenance-description" name="maintenance-description" onChange={handleDescriptionChange} value={description}/>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <ButtonGroup size="lg" className="mb-2 float-end">
                                            <Button id="add-files" onClick={onShowWidget}>Add Photos</Button>
                                            <Button id="save-files" onClick={onCheckPhotos}>Save</Button>
                                        </ButtonGroup>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                    <Modal show={showModal} onHide={onCancel} backdrop="static" keyboard={false} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Home Services</Modal.Title>
                        </Modal.Header>
                        <Modal.Body> {modalMessage} </Modal.Body>
                        <Modal.Footer>
                            <div>{savingMessage}</div>
                            <Button variant="primary" onClick={onSave} disabled={disableSubmit}>Submit</Button>
                            <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    )   
}

export default Upload