import React from "react"
import Async from 'react-async'
import State from "./State"
import { ListGroup, ButtonGroup, Button, Form } from "react-bootstrap"
import StickyNav from "./StickyNav"
import "bootstrap/dist/css/bootstrap.min.css"

const loadPhotos = async ( { extid}) => {
    let url = `/api/serviceappointment/related/sali/view?appointmentid=${extid}`
    const res = await fetch(url)
    if (!res.ok) throw new Error(res.statusText)
    return res.json()
}

function ViewInvoice() {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('extid')
    const name = queryParams.get('name')
    document.title = `View Invoice (${name})`
    
    const handleUpdate = (e) => {
        let id = e.target.getAttribute('data-sali-id')
        let name = document.querySelector(`#${id}_name`).value
        let type = document.querySelector(`#${id}_type`).value
        let desc = document.querySelector(`#${id}_desc`).value
        let rate = document.querySelector(`#${id}_rate`).value
        let quantity = document.querySelector(`#${id}_quantity`).value
        const actions = document.querySelectorAll(".button-action")
        actions.forEach(p => p.setAttribute('disabled',''))
        const request = {
            Id: id,
            Type__c: type,
            Description__c: desc,
            Rate__c: rate,
            Quantity__c: quantity
        }
        let url = `/api/sali/update`
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        }

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.success) {
                    alert(`Success: ${name} updated`);
                    window.location.reload()
                } else {
                    alert(`Error : updating ${name}`)
                }
            })

    }

    const handleDelete = (e) => {
        let id = e.target.getAttribute('data-sali-id')
        let name = document.querySelector(`#${id}_name`).value
        const actions = document.querySelectorAll(".button-action")
        actions.forEach(p => p.setAttribute('disabled',''))
        const request = {
            Id: id
        }
        let url = `/api/sali/delete`
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        }

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.success) {
                    alert(`Success: ${name} deleted`);
                    window.location.reload()
                } else {
                    alert(`Error : delete ${name}`)
                }
            })
    }


    return (
        <>
        <StickyNav/>
        { (!id) ?
            <State status="error" title="Internal Server Error" message="id is required."/>
            :
            <Async promiseFn={loadPhotos} extid={id}>
                <Async.Loading><State status="info" title="Loading ..." message="Fetching data, please wait!"/></Async.Loading>
                <Async.Rejected>
                    {error => <State status="error" title="Error" message="Something went wrong."/> }
                </Async.Rejected>
                <Async.Fulfilled>
                    {data => {
                        return (
                            <ListGroup className="sali-container">
                                {data[0].Service_Appointment_Line_Items__r === null ?
                                    <ListGroup.Item key="no-sali">
                                        <State status="info" title="" message="No Invoice Line Items"/>
                                    </ListGroup.Item>
                                : data[0].Service_Appointment_Line_Items__r.records.map(sali=> (
                                    <ListGroup.Item key={sali.Id}>
                                        <Form className="font-eina">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control id={sali.Id + "_name"} type="text" placeholder="name" value={sali.Name} disabled />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Type</Form.Label>
                                                <Form.Select id={sali.Id + "_type"} defaultValue={sali.Type__c} disabled={(data[0].Status === 'Completed' || sali.Type__c === 'Time & Labour')} data-sali-id={sali.Id}>
                                                    <option value="Time & Labour" disabled>Labor</option>
                                                    <option value="Product & Material">Product & Material</option>
                                                    <option value="Other Charge">Other Charge</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label> {!(sali.Type__c === 'Product & Material') ?  "Description" : "Item Name" } </Form.Label>
                                                <Form.Control id={sali.Id + "_desc"} type="text" placeholder={!(sali.Type__c === 'Product & Material') ?  "Description" : "Item Name" } defaultValue={sali.Description__c} disabled={(data[0].Status === 'Completed' || sali.Type__c === 'Time & Labour')} />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label> {!(sali.Type__c === 'Time & Labour') ?  "Price" : "Rate" } </Form.Label>
                                                <Form.Control id={sali.Id + "_rate"} type="number" placeholder={!(sali.Type__c === 'Time & Labour') ?  "Price" : "Rate" } defaultValue={sali.Rate__c} disabled={(data[0].Status === 'Completed' || sali.Type__c === 'Time & Labour')}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label> {!(sali.Type__c === 'Time & Labour') ?  "Quantity" : "Hours" } </Form.Label>
                                                <Form.Control id={sali.Id + "_quantity"} type="number" placeholder={!(sali.Type__c === 'Time & Labour') ?  "Quantity" : "Hours" } defaultValue={sali.Quantity__c} disabled={data[0].Status === 'Completed'}/>
                                            </Form.Group>
                                        </Form>
                                        <ButtonGroup aria-label="Actions" className="float-end">
                                            <Button className="button-action" variant="primary" onClick={handleUpdate} data-sali-id={sali.Id} disabled={data[0].Status === 'Completed'}>Update</Button>
                                            <Button className="button-action" variant="danger" onClick={handleDelete} data-sali-id={sali.Id} disabled={data[0].Status === 'Completed'}>Delete</Button>
                                        </ButtonGroup>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )
                    }}
                </Async.Fulfilled>
            </Async>
        }
        </>
    )
}

export default ViewInvoice