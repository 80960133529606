import React, { useState , useRef } from "react"
import StickyNav from "./StickyNav"
import { Button, Modal, CloseButton, ProgressBar, Alert } from "react-bootstrap"
import documentLogo from "../images/file-30.png"
import imageLogo from "../images/image-file-30.png"
import pdfLogo from "../images/pdf-30.png"
import zipLogo from "../images/zip-30.png"
import audioLogo from "../images/audio-file-30.png"
import videoLogo from "../images/video-file-30.png"
import "bootstrap/dist/css/bootstrap.min.css"

function S3DocumentUpload() {
    document.title = `Upload Documents`
    const queryParams = new URLSearchParams(window.location.search)
    const allParams = queryParams.toString()
    const referrer = queryParams.get('referrer')
    const removeHeader = queryParams.get('removeHeader')
    //use states
    const [selectButtonLabel, setSelectButtonLabel] = useState('Select a documents')
    const [showDocumentList, setShowDocumentList] = useState(false)
    const [fileList, setFileList] = useState([])
    const [showInformationModal, setShowInformationErrorModal] = useState(false)
    const [informationTitle, setInformationTitle] = useState('')
    const [informationMessage, setInformationMessage] = useState('')
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationTitle, setConfirmationTitle] = useState('')
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [disableSave, setDisableSave] = useState(false)
    const [dragActive, setDragActive] = useState(false)
    //use ref
    const inputRef = useRef(null)
    const addFile = (newFile) => setFileList(state => [...state, newFile])
  
    const handleDrag = function(e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }
  
    const handleDrop = function(e) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files)
        }
    }
    
    const handleChange = function(e) {
        e.preventDefault()
        setFileList([])
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files)
        }
    }

    const onButtonClick = () => {
        inputRef.current.click()
    }

    const onSave = async () => {
        setDisableSave(true)
        setConfirmationTitle('Saving')
        setConfirmationMessage(`Saving ${fileList.length} ${(fileList.length > 1) ? "Documents" : "Document" }?`)
        const files = fileList
        let url = `/api/document/upload?${allParams}`
        var fd = new FormData()
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            fd.append("files", file)
        }
        const requestOptions = {
            method: 'POST',
            body: fd
        }

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                let dataparsed = handleResponseData(data)
                setFileList([])
                setShowConfirmationModal(false)
                setDisableSave(false)
                setSelectButtonLabel('Select a documents')
                setShowInformationErrorModal(true)
                setInformationTitle(`Upload Completed`)
                setInformationMessage(dataparsed)
            })
    }

    const handleResponseData = (data) => {
        const rows = [];
        const files = fileList
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            var found = false;
            for (let x in data) {
                if (file.name === data[x].Document_Name__c) {
                    let link = `${referrer}/${data[x].Id}`
                    rows.push(<Alert key={file.name} variant="success"><img src={fileImage(file.type)} alt="document type" /><a href={link} target="_blank" rel="noreferrer">{file.name}</a></Alert>)
                    found = true;
                    break;
                }
            }
            if (!found) {
                rows.push(<Alert key={file.name} variant="danger">{file.name}</Alert>)
            }
        }
         
        return (
            <>{rows}</>
        )
    }
    
    const handleFiles = function(files) {
        setShowDocumentList(true)
        setFileList([])
        setSelectButtonLabel(`Selected ${files.length} ${(files.length > 1) ? "Documents" : "Document" }`)
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i)
            addFile(file)
        }
    }

    const handleSave = function() {
        if (fileList.length === 0) {
            setShowInformationErrorModal(true)
            setInformationTitle('Error')
            setInformationMessage('No files selected')
        } else if (fileList.length > 10) {
            setShowInformationErrorModal(true)
            setInformationTitle('Error')
            setInformationMessage('Maximum files to upload is 10.')
        }
        else {
            setConfirmationTitle('Confirmation')
            setShowConfirmationModal(true)
            setConfirmationMessage(`Save ${fileList.length} ${(fileList.length > 1) ? "Documents" : "Document" }?`)
        }
    }
    
    const removeFile = (event, name) => {
        event.preventDefault()
        var tempFileList = [...fileList]
        const index = tempFileList.findIndex(file => {
            return file.name === name
        })
        tempFileList.splice(index, 1)
        setFileList(tempFileList)
        setSelectButtonLabel(`${(tempFileList.length === 0) ? "Select a" : "Selected"} ${(tempFileList.length === 0 ) ? "" : tempFileList.length} ${(tempFileList.length > 1) ? "Documents" : "Document" }`)
    }

    const makeshort = function(n) {
        var split = n.split('.')
        var filename = split[0]
        var extension = split[1]
        if (filename.length < 15) return n
        return `${filename.substring(0, 15)}...${filename.substring(filename.length - 2, filename.length)}.${extension}`
    }
    const formatFileSize = function(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    const fileImage = function(type) {
        if (type.includes("image")) {
            return imageLogo
        }
        if (type.includes("pdf")) {
            return pdfLogo
        }
        if (type.includes("zip")) {
            return zipLogo
        }
        if (type.includes("audio")) {
            return audioLogo
        }
        if (type.includes("video")) {
            return videoLogo
        }
        return documentLogo
    }
    return (
        <>
            { (removeHeader === 'false' || removeHeader === null)&& 
                <StickyNav/>
            }
            <div className="page">
                <center><span className="upload-document-title">Upload Documents</span></center>
                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                        <div>
                            <p>Drag and drop your documents here or</p>
                            <button className="upload-button" onClick={onButtonClick}>{selectButtonLabel}</button>
                            <br/>
                            <Button id="s3-save-button" className="upload-button" onClick={handleSave}>Save Documents</Button>
                        </div>
                    </label>
                    { dragActive && 
                        <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                        </div>
                    }
                </form>
                <br/>
                { showDocumentList && 
                    <div id="file-list">
                        <table>
                            <tr>
                                <th>File Name</th>
                                <th>File Size</th>
                                <th>Action</th>
                            </tr>
                            {
                                fileList.map(file => (
                                    <tr key={file.name}>
                                        <td><img src={fileImage(file.type)} alt="document" />{makeshort(file.name)}</td>
                                        <td>{formatFileSize(file.size)}</td>
                                        <td><CloseButton onClick={(e) => { removeFile(e, file.name ) }}/></td>
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                }
                
            </div>
            <InformationModal
                show={showInformationModal}
                title={informationTitle}
                message={informationMessage}
                onHide={() => setShowInformationErrorModal(false)}
            />
            <ConfirmationModal
                show={showConfirmationModal}
                title={confirmationTitle}
                message={confirmationMessage}
                onHide={() => setShowConfirmationModal(false)}
                onSubmit={onSave}
                onDisabled={disableSave}
            />
        </>
    )   
}

function InformationModal(props) {
    return(
        <Modal {...props} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title className="font-eina">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-eina"> {props.message} </Modal.Body>
            <Modal.Footer>
                <Button className="font-eina" variant="secondary" onClick={props.onHide}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}

function ConfirmationModal(props) {
    return(
        <Modal {...props} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title className="font-eina">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font-eina"> 
                {props.message} 
                {
                    props.onDisabled &&
                    <ProgressBar className="orange-progress-bar" animated now={100} />
                }
            </Modal.Body>
            <Modal.Footer>
                <Button className="font-eina" variant="primary" onClick={props.onSubmit} disabled={props.onDisabled}>Save</Button>
                <Button className="font-eina" variant="secondary" onClick={props.onHide} disabled={props.onDisabled}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default S3DocumentUpload