import React, { Component } from "react"

class State extends Component {
    render() {
        var comp
        if (this.props.status === 'error') {
            comp = error(this.props.title, this.props.message)
        } else if (this.props.status === 'success') {
            comp = success(this.props.title, this.props.message)
        } else if (this.props.status === 'info') {
            comp = info(this.props.title, this.props.message)
        }
        return(
            <> {comp} </>
        )
    }
}

function error (title, message) {
    return (
        <div id="status" class="alert alert-danger" role="alert">
            <center><h1>{title}</h1></center>
            <center><h2>{message}</h2></center>
        </div> 
    )
}

function info (title, message) {
    return (
        <div id="status" class="alert alert-info" role="alert">
            <center><h1>{title}</h1></center>
            <center><h2>{message}</h2></center>
        </div>
    )
}

function success (title, message) {
    return (
        <div id="status" class="alert alert-success" role="alert">
            <center><h1>{title}</h1></center>
            <center><h2>{message}</h2></center>
        </div>
    )
}

export default State